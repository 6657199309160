import { useTranslation } from 'react-i18next';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { setTag, withProfiler } from '@sentry/react';
import app from '@/utils/app';

const SentryProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setTag('idk.lang', i18n.language);
  }, [i18n]);

  useEffect(() => {
    setTag('idk.env', app.env);
  }, []);

  return <>{children}</>;
};

export default withProfiler(SentryProvider);
