import { styled } from 'styled-components';
import React, { FC } from 'react';
import { Styling, hostname, ResponsiveImage } from '@idk-web/core-ui';
import idkollen from '@/assets/svg/idkollen.svg';
import identisure from '@/assets/svg/identisure.svg';

const Container = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  justify-content: space-between;
  min-height: 80px;
  z-index: ${Styling.zIndex('header')};
  box-shadow: ${Styling.shadow('header')};
  background-color: ${({ theme }) => theme.palette.grayscale.white};
  padding: 0 ${Styling.spacing(8)};
  @media (max-width: ${Styling.breakpoint('lg')}px) {
    padding: 0 ${Styling.spacing(4)};
  }
  @media (max-width: ${Styling.breakpoint('sm')}px) {
    padding: 0 ${Styling.spacing(2)};
  }
`;

const Logo = styled(ResponsiveImage).attrs({
  alt: 'Logo', // TODO translate
})`
  max-width: 160px;
  max-height: 120px;
  padding: ${Styling.spacing(2)} 0;
`;

export type HeaderProps = {
  logo?: string;
};

const Header: FC<HeaderProps> = ({ logo }) => {
  return (
    <Container>
      {logo ? (
        <Logo src={logo} />
      ) : (
        <Logo src={hostname.tld === 'se' ? idkollen : identisure} />
      )}
    </Container>
  );
};

export default Header;
