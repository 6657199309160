import { Route } from 'react-router-dom';
import {
  init,
  BrowserTracing,
  Replay,
  reactRouterV5Instrumentation,
  withSentryRouting,
} from '@sentry/react';
import { SENSITIVE_CLASS_NAME } from '@idk-web/core-ui';
import history from '@/history';

const {
  VITE_SENTRY_DSN = '',
  VITE_APP_ENV = 'unknown',
  VITE_COMMIT_HASH = 'unknown',
} = import.meta.env;

export function initialize(): void {
  if (!VITE_SENTRY_DSN) {
    console.log('Skipping Sentry initialization: DSN not set');
    return;
  }

  init({
    dsn: VITE_SENTRY_DSN,
    release: `idk-web-portal@${VITE_COMMIT_HASH}`,
    environment: VITE_APP_ENV,
    autoSessionTracking: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
      new Replay({
        mask: [SENSITIVE_CLASS_NAME],
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: true,
      }),
    ],
    tracePropagationTargets: ['localhost', 'idkollen.se', /^\//],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.1,
    sampleRate: 1.0,
    tracesSampleRate: 0.005,
  });
}

export const SentryRoute = withSentryRouting(Route);
