import { styled } from 'styled-components';
import { Switch } from 'react-router-dom';
import React, { FC, Suspense } from 'react';
import { Box, ErrorBoundary } from '@idk-web/core-ui';
import { SentryRoute } from '@/sentry';
import routes from '@/components/form/routes';
import Layout from '@/components/form/Layout';
import ScheduledMaintenanceMessage from '@/components/common/ScheduledMaintenanceMessage';
import BrowserSupportMessage from '@/components/common/BrowserSupportMessage';

const ErrorScreen = React.lazy(() => import('@/components/error/ErrorScreen'));
const LoginCallback = React.lazy(
  () => import('@/components/common/auth/LoginCallback'),
);
const ViewFormScreen = React.lazy(
  () => import('@/components/form/view/ViewFormScreen'),
);
const NotFoundScreen = React.lazy(
  () => import('@/components/notFound/NotFoundScreen'),
);

const Container = styled(Box).attrs({
  direction: 'vertical',
})``;

const FormApp: FC = () => {
  return (
    <Container>
      <ErrorBoundary fallback={ErrorScreen}>
        <BrowserSupportMessage />
        <ScheduledMaintenanceMessage />
        <Suspense fallback={null}>
          <Switch>
            <SentryRoute path={routes.authCallback}>
              <LoginCallback redirect={routes.home} />
            </SentryRoute>
            <SentryRoute
              exact
              path={routes.viewFormByCompanyAndName}
              component={ViewFormScreen}
            />
            <SentryRoute
              exact
              path={routes.viewFormByName}
              component={ViewFormScreen}
            />
            <SentryRoute exact path={routes.home} component={ViewFormScreen} />
            <SentryRoute
              render={() => (
                <Layout>
                  <NotFoundScreen />
                </Layout>
              )}
            />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
};

export default FormApp;
